import "./Home.css";
import pivotea from "../../assets/newsletter.png";
import Card from "../../components/Card/Card";
import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Button from "../../components/Button/Button";

export default function Home() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      console.log("context - not logged in");
      //1. Check localstorage
      if (JSON.parse(window.localStorage.getItem("PIVOTEA_LOGIN")) !== null) {
        console.log("Login found in localstorage");
        //User is logged in
        const decodedToken = jwtDecode(
          JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN"))
        );
        if (decodedToken.exp < Math.floor(Date.now() / 1000)) {
          //Token has expired, logout user
          console.log("expired token");
          setAuthUser(null);
          setIsLoggedIn(false);
          window.localStorage.clear();
          navigate("/login");
        } else {
          //Valid token
          console.log("valid token");
          setAuthUser({
            name: JSON.parse(window.localStorage.getItem("PIVOTEA_NAME")),
            lastName: JSON.parse(
              window.localStorage.getItem("PIVOTEA_LASTNAME")
            ),
            id: JSON.parse(window.localStorage.getItem("PIVOTEA_UID")),
            token: JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN")),
            current_module: JSON.parse(window.localStorage.getItem("PIVOTEA_CURRENT_MODULE")),
            current_step: JSON.parse(window.localStorage.getItem("PIVOTEA_CURRENT_STEP")),
                email: JSON.parse(window.localStorage.getItem("PIVOTEA_EMAIL"))

          });
          setIsLoggedIn(true);
        }
      } else {
        console.log("no storage")
        //User is not logged in,
        navigate("/login");
      }
    }
  }, []);

  const subscribe = () => {
    console.log(email);
  };
  const sampleData = {
    cards: [
      {
        title: "Vocacionamiento",
        description:
          "Te ayudaremos a diseñar un propósito de vida que te permita definir tu siguiente paso profesional.",
        duration: "12 semanas",
        participants: "8 participantes",
        cover:
          "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "in progress",
        link: "/vocacionamiento",
        linkType: "internal"
      },
      {
        title: "Formación",
        description:
          "Accede a los recursos alineados a tus metas y habilidades",
        duration: "12 semanas",
        participants: "8 participantes",
        cover:
          "https://images.unsplash.com/photo-1615914143778-1a1a6e50c5dd?q=80&w=2068&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Explorar",
        link: "https://courses.skillingcenter.com/",
        linkType: "external"
      },
      {
        title: "Acompañamiento",
        description:
          "Ponte en contacto con tu acompañador, quien te guiará en el proceso.",
        duration: "12 semanas",
        participants: "8 participantes",
        cover:
          "https://images.unsplash.com/photo-1612832164313-ac0d7e07b5ce?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        status: "Ir a la comunidad",
        link: "https://chat.whatsapp.com/Ffdejw3Sx6iKboxL1NmlMp",
        linkType: "external"
      },
    ],
  };
  const cards = [];
  sampleData.cards.forEach((card) => {
    cards.push(
      <Card
        cover={card.cover}
        title={card.title}
        description={card.description}
        duration={card.duration}
        participants={card.participants}
        status={card.status}
        link={card.link}
        linkType={card.linkType}
      />
    );
  });
  return (
    <div className="home-layout">
      <div className="home-banner">
        <h1>TU ESPACIO DE TRABAJO</h1>
        <p>
          Encuentra todo lo necesario para tu experiencia dentro de PIVOTEA,
          desde que inicias hasta que terminas. No te preocupes, te acompañamos
          en cada paso.
        </p>
      </div>
      <div className="home-card-container">{cards}</div>
      <div className="newsletter-container">
        <div className="newsletter-text">
          <h4>BIENVENID@ A PIVOTEA</h4>
          <p>
            Si tienes alguna duda, comentario o simplemente quieres saludar,
            envía un correo a:{" "}
            <a href="mailto:competencias@servicios.tecmilenio.mx" target="_blank">
              competencias@servicios.tecmilenio.mx
            </a>
          </p>
        </div>
        <div className="newsletter-cta">
          <img src={pivotea} alt="Pivotea" />
          <div className="newsletter-form">
            <h4>FORMA PARTE DE FUTUROS PILOTOS</h4>
            <div>
              <p>Comparte tu email con nosotros</p>
              <input
                type="email"
                value={email}
                placeholder="usuario@email.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <Button
              type="outline"
              corners="rounded"
              text="Suscribirme"
              size="sm"
              onClick={subscribe}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
