import React from "react";
import logo from "../../assets/pivotea-logo-green.png";
import banner from "../../assets/green-banner.png";
import lightning from "../../assets/lightning.png";
import "./ProyectoAprendizaje.css";

function ProyectoAprendizaje({aprendizajeData}) {
  const userName = (localStorage.getItem("PIVOTEA_NAME") + " " + localStorage.getItem("PIVOTEA_LASTNAME")).replace(/"/g, '');
  

  const strengths = [];
  aprendizajeData.answersFortalezas.forEach((fortaleza) => {
    strengths.push(
      <>
        <li>{fortaleza.answer}</li>
        <span>{fortaleza.description}</span>
      </>
    );
  });

  const opportunities = [];
  const opportunitiesTemp = [];



const splitStringAtHalf = (string) => {
  const middleIndex = Math.floor(string.length / 2);
  const spaceIndex = string.indexOf(' ', middleIndex);

  if (spaceIndex === -1) {
    // No hay espacio después de la mitad del string, no se puede dividir
    return [string];
  }

  const part1 = string.substring(0, spaceIndex);
  const part2 = string.substring(spaceIndex + 1);
  
  return [part1, part2];
}
  for (let i = 0; i < aprendizajeData.answersOportunidades.length; i++) {
    const halfString = splitStringAtHalf(aprendizajeData.answersOportunidades[i+1].answer)
    opportunitiesTemp.push({
      title: aprendizajeData.answersOportunidades[i].answer,
      statement: halfString[0],
      justification: halfString[1]
    })
    i++
  }
  opportunitiesTemp.forEach((opportunity) => {
    opportunities.push(
      <div className="opportunity">
        <div className="opp-header">{opportunity.title}</div>
        <span>{opportunity.statement}</span>
        <p>{opportunity.justification}</p>
      </div>
    );
  });
  return (
    <div className="proyecto-layout">
      <div className="proyecto-header">
        <img src={logo} className="proyecto-logo" alt="Pivotea" />
        <p>Proyecto de aprendizaje</p>
      </div>
      <div className="proyecto-banner">
        <h3>{userName}</h3>
        <p>{aprendizajeData.answersProposito[0].answer}</p>
        <img src={banner} alt="" />
      </div>
      <div className="proyecto-columns">
        <div className="strengths">
          <img src={lightning} alt="" />
          <h4>Fortalezas de carácter</h4>
          <ol>{strengths}</ol>
        </div>
        <div className="opportunities">
          <h4>
            Portafolio de vida:
            <br />
            Mis áreas de mejora
          </h4>
          {opportunities}
        </div>
      </div>
    </div>
  );
}

export default ProyectoAprendizaje;
