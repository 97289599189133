import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { jwtDecode } from "jwt-decode";

import Button from "../../components/Button/Button";

import "./LandingPage.css";
import pivotea from "../../assets/newsletter.png";
import hero from "../../assets/video.png";
import icon_base from "../../assets/icon_base.png";
import icon_dark from "../../assets/icon_dark.png";
import icon_light from "../../assets/icon_light.png";
import first from "../../assets/1.png";
import second from "../../assets/2.png";
import third from "../../assets/3.png";
import fourth from "../../assets/4.png";
import fifth from "../../assets/5.png";

const LandingPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();

    useEffect(() => {
        if (!isLoggedIn) {
          //1. Check localstorage
          if (JSON.parse(window.localStorage.getItem("PIVOTEA_LOGIN")) !== null) {
            //User is logged in
            const decodedToken = jwtDecode(
              JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN"))
            );
            if (decodedToken.exp < Math.floor(Date.now() / 1000)) {
              //Token has expired, logout user
              setAuthUser(null);
              setIsLoggedIn(false);
              window.localStorage.clear();
            } else {
              //Valid token
              setAuthUser({
                name: JSON.parse(window.localStorage.getItem("PIVOTEA_NAME")),
                lastName: JSON.parse(
                  window.localStorage.getItem("PIVOTEA_LASTNAME")
                ),
                id: JSON.parse(window.localStorage.getItem("PIVOTEA_UID")),
                token: JSON.parse(window.localStorage.getItem("PIVOTEA_TOKEN")),
                current_module: JSON.parse(window.localStorage.getItem("PIVOTEA_CURRENT_MODULE")),
                current_step: JSON.parse(window.localStorage.getItem("PIVOTEA_CURRENT_STEP")),
                email: JSON.parse(window.localStorage.getItem("PIVOTEA_EMAIL"))

              });
              setIsLoggedIn(true);
            }
          } else {
            //User is not logged in,
          }
        }
      }, []);
    
  const subscribe = () => {
    console.log(email);
  };

  return (
    <div className="landing">
        <div className="landing-hero">
            <div className="landing-hero-text">
                <h1>LA INCUBADORA DE LA SIGUIENTE ETAPA DE TU VIDA</h1>
                <p>
                    Aprende a pilotear drones y conviértete en un piloto profesional.
                </p>
                <Button
                    type="outline"
                    corners="rounded"
                    text="Iniciar"
                    size="md"
                    onClick={() => {
                        navigate("/miespacio");
                    }}
                />
            </div>
            <div className="landing-hero-image">
                <img src={hero} alt="Pivotea" />
            </div>
        </div>
        <div className="landing-description">
            <strong>PIVOTEA</strong> es un programa de aprendizaje personalizado que ofrece mentorías diseñado para adquieras y mejores las habilidades que te ayudarán en tu siguiente paso profesional. ¡Descubre tu trayectoria ideal profesional y accede a contenidos de calidad con una membresía del Skilling Center de Tecmilenio para desarrollar tus habilidades!*
        </div>
        <div className="landing-separator">
            Vivirás esta experiencia teniendo 3 pilares en mente, los cuáles te ayudarán a: reflexionar, dirigir y tomar acción.
        </div>
        <div className="landing-tools">
            <div className="landing-tool">
                <img src={icon_light} alt="Reflexionar" />
                <h4>VOCACIONAMIENTO</h4>
                <p>
                    Aquí definirás tus sueños y objetivos descubriendo tu propósito de vida.
                </p>
            </div>
            <div className="landing-tool">
                <img src={icon_dark} alt="Reflexionar" />
                <h4>ACOMPAÑAMIENTO</h4>
                <p>
                    Generarás planes de acción concretos con coaching profesional.
                </p>
            </div>
            <div className="landing-tool">
                <img src={icon_base} alt="Reflexionar" />
                <h4>FORMACIÓN</h4>
                <p>
                    Fortalecerás tu perfil profesional con la mejor oferta educativa.
                </p>
            </div>
        </div>
        <div className="landing-cta">
            <div className="landing-cta-text">
                <p>EN TAN SÓLO 3 MESES:</p>
                <h4>¡TRANSFORMA TUS SUEÑOS <br />EN LOGROS PROFESIONALES!</h4>
            </div>
            <div className="landing-cta-button">
                <Button
                    type="outline"
                    corners="rounded"
                    text="ENTRA A PIVOTEA &rarr;"
                    size="md"
                    onClick={() => {
                        navigate("/signup");
                    }}
                />
            </div>
        </div>
        <div className="landing-cards">
            <h4>BENEFICIOS DE ESTAR EN PIVOTEA</h4>
            <div className="landing-card-wrapper">
                <div className="landing-card">
                    <img src={first} alt="Reflexionar" />
                    <p>Establecimiento de un proyecto de aprendizaje</p>
                </div>
                <div className="landing-card">
                    <img src={second} alt="Reflexionar" />
                    <p>Fortalecimiento de tu perfil profesional: propósito de vida, creación de CV, aplicaciones y entrevistas de trabajo</p>
                </div>
                <div className="landing-card">
                    <img src={third} alt="Reflexionar" />
                    <p>Una comunidad donde podrás inspirarte de las experiencias de los demás aprendedores</p>
                </div>
                <div className="landing-card">
                    <img src={fourth} alt="Reflexionar" />
                    <p>Generación de escenarios futuros para tu brújula profesional con el taller de Life Design</p>
                </div>
                <div className="landing-card">
                    <img src={fifth} alt="Reflexionar" />
                    <p>Insignias digitales al finalizar las rutas de formación que concluyas</p>
                </div>
            </div>
        </div>
        <div className="newsletter-container">
                <div className="newsletter-text">
                    <h4>BIENVENID@ A PIVOTEA</h4>
                    <p>
                        Si tienes alguna duda, comentario o simplemente quieres saludar,
                        envía un correo a:{" "}
                        <a href="mailto:competencias@servicios.tecmilenio.mx" target="_blank">
                            competencias@servicios.tecmilenio.mx
                        </a>
                    </p>
                </div>
                <div className="newsletter-cta">
                    <img src={pivotea} alt="Pivotea" />
                    <div className="newsletter-form">
                        <h4>FORMA PARTE DE FUTUROS PILOTOS</h4>
                        <div>
                            <p>Comparte tu email con nosotros</p>
                            <input
                                type="email"
                                value={email}
                                placeholder="usuario@email.com"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <Button
                            type="outline"
                            corners="rounded"
                            text="Suscribirme"
                            size="sm"
                            onClick={subscribe}
                        />
                    </div>
                </div>
            </div>
    </div>
  )
}

export default LandingPage